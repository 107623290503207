import { useMutation } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Dropdown from 'src/components/shared/Dropdown';
import StringField from 'src/components/shared/StringField';
import {
  useFactoryQuoteTagGroupListQuery,
  useCustomerListQuery,
  useFactoryQuoteTagListQuery,
  useModelTypeListQuery,
} from 'src/hooks';
import type { FactoryQuoteTag, FactoryQuoteTagGroup, ModelType } from 'src/models/factoryQuote';
import {
  addFactoryQuoteTag,
  addFactoryQuoteTagGroup,
  updateFactoryQuoteTag,
  updateFactoryQuoteTagGroup,
} from 'src/apis';
import type { Customer } from 'src/models/customer';
import NumberField from 'src/components/shared/NumberField';
import FilterableDropdown from 'src/components/shared/FilterableDropdown';
import BooleanField from 'src/components/shared/BooleanField';
import useNotification from 'src/hooks/useNotification';
import { FACTORY_QUOTE_TAG_FIELD_TO_NAME, FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME } from '../constants';

const groupTypeOptions = Object.keys(FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME)
  .map((field) => field as keyof typeof FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME)
  .map((field) => ({ value: field, label: FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME[field] }));

function FactoryQuoteTagEditDialog({
  tag: originalTag,
  onRequestClose,
}: {
  tag?: FactoryQuoteTag;
  onRequestClose: () => void;
}) {
  const { factoryQuoteTagGroupList, refetchFactoryQuoteTagGroupList, setFactoryQuoteTagGroupList } =
    useFactoryQuoteTagGroupListQuery();

  const { refetchFactoryQuoteTagList } = useFactoryQuoteTagListQuery();

  const { modelTypeList } = useModelTypeListQuery();

  const { customerList } = useCustomerListQuery();

  const modelTypeOptions = useMemo(
    () => modelTypeList.map(({ name }) => ({ value: name, label: name })),
    [modelTypeList],
  );

  const customerOptions = useMemo(
    () => customerList.map(({ id, code, name }) => ({ value: id, label: `${code} (${name})` })),
    [customerList],
  );

  const originalTagGroup = useMemo(
    () => (originalTag ? factoryQuoteTagGroupList?.find(({ id }) => id === originalTag.group) : undefined),
    [originalTag, factoryQuoteTagGroupList],
  );

  const [selectedGroupType, setSelectedGroupType] = useState<FactoryQuoteTagGroup['type'] | undefined>(
    originalTagGroup?.type,
  );

  const [selectedGroupId, setSelectedGroupId] = useState<FactoryQuoteTagGroup['id'] | undefined>(
    originalTag?.group,
  );

  const [tagName, setTagName] = useState<string | undefined>(originalTag?.name);

  const [selectedModelTypeOption, setSelectedModelTypeOption] = useState<{
    label: ModelType['name'];
    value: ModelType['name'];
  } | null>(null);

  useEffect(() => {
    if (!originalTag) return;
    setSelectedModelTypeOption(modelTypeOptions.find(({ value }) => value === originalTag.modelType) ?? null);
  }, [modelTypeOptions, originalTag]);

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<{
    label: string;
    value: Customer['id'];
  } | null>(null);

  useEffect(() => {
    if (!originalTag) return;
    setSelectedCustomerOption(customerOptions.find(({ value }) => value === originalTag.customerId) ?? null);
  }, [customerOptions, originalTag]);

  const [cost, setCost] = useState<FactoryQuoteTag['cost'] | undefined>(originalTag?.cost);

  const [disabled, setDisabled] = useState<FactoryQuoteTag['disabled']>(originalTag?.disabled);

  const [tagGroupName, setTagGroupName] = useState<string | undefined>(originalTagGroup?.name);

  const [target, setTarget] = useState<'tag' | 'tagGroup'>('tag');

  const [isSubmitting, setSubmitting] = useState(false);

  const { notification, showNotification } = useNotification();

  const addFactoryQuoteTagGroupMutation = useMutation({ mutationFn: addFactoryQuoteTagGroup });

  const addFactoryQuoteTagMutation = useMutation({ mutationFn: addFactoryQuoteTag });

  const updateFactoryQuoteTagGroupMutation = useMutation({ mutationFn: updateFactoryQuoteTagGroup });

  const updateFactoryQuoteTagMutation = useMutation({ mutationFn: updateFactoryQuoteTag });

  const onSubmit = useMemo(() => {
    if (target === 'tag' && selectedGroupId !== undefined && tagName !== undefined && cost !== undefined) {
      return async () => {
        try {
          setSubmitting(true);

          if (originalTag) {
            await updateFactoryQuoteTagMutation.mutateAsync({
              ...originalTag,
              name: tagName,
              cost,
              group: selectedGroupId,
              modelType: selectedModelTypeOption?.value,
              customerId: selectedCustomerOption?.value,
              disabled: disabled ?? false,
            });
          } else {
            await addFactoryQuoteTagMutation.mutateAsync({
              name: tagName,
              cost,
              group: selectedGroupId,
              modelType: selectedModelTypeOption?.value,
              customerId: selectedCustomerOption?.value,
              disabled: disabled ?? false,
            });
          }

          await refetchFactoryQuoteTagList();
          onRequestClose();
        } catch (error) {
          if (error instanceof Error) {
            showNotification(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    if (target === 'tagGroup' && tagGroupName !== undefined && selectedGroupType === 'direct') {
      return async () => {
        try {
          setSubmitting(true);

          if (originalTagGroup) {
            await updateFactoryQuoteTagGroupMutation.mutateAsync({
              ...originalTagGroup,
              name: tagGroupName,
            });
          } else {
            const newFactoryQuoteTagGroup = await addFactoryQuoteTagGroupMutation.mutateAsync({
              name: tagGroupName,
              type: selectedGroupType,
            });
            setFactoryQuoteTagGroupList((prev) => [...prev, newFactoryQuoteTagGroup]);
            setSelectedGroupId(newFactoryQuoteTagGroup.id);
            setTagGroupName(undefined);
          }

          await refetchFactoryQuoteTagGroupList();
          setTarget('tag');
        } catch (error) {
          if (error instanceof Error) {
            showNotification(error.message);
          } else {
            console.error(error);
          }
        } finally {
          setSubmitting(false);
        }
      };
    }

    return undefined;
  }, [
    addFactoryQuoteTagGroupMutation,
    addFactoryQuoteTagMutation,
    cost,
    disabled,
    onRequestClose,
    originalTag,
    originalTagGroup,
    refetchFactoryQuoteTagGroupList,
    refetchFactoryQuoteTagList,
    selectedCustomerOption?.value,
    selectedGroupId,
    selectedGroupType,
    selectedModelTypeOption?.value,
    setFactoryQuoteTagGroupList,
    showNotification,
    tagGroupName,
    tagName,
    target,
    updateFactoryQuoteTagGroupMutation,
    updateFactoryQuoteTagMutation,
  ]);

  const groupOptions = useMemo(
    () =>
      factoryQuoteTagGroupList
        .filter((group) => group.type === selectedGroupType)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    [factoryQuoteTagGroupList, selectedGroupType],
  );

  return (
    <>
      <Dialog open fullWidth maxWidth="lg">
        {target === 'tag' && <DialogTitle>{`${originalTag ? '編輯' : '新增'}工廠報價屬性`}</DialogTitle>}
        {target === 'tagGroup' && <DialogTitle>{`${originalTag ? '編輯' : '新增'}直接費用`}</DialogTitle>}
        <DialogContent>
          <Box sx={{ pt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {target === 'tag' && (
              <>
                <Dropdown
                  label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.groupType}
                  options={groupTypeOptions}
                  value={selectedGroupType}
                  onChange={setSelectedGroupType}
                  disabled={!!originalTag}
                />
                <Stack direction="row" alignItems="center" gap={1}>
                  <Dropdown
                    label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.groupName}
                    options={groupOptions}
                    value={selectedGroupId}
                    onChange={setSelectedGroupId}
                    disabled={selectedGroupType === undefined || !!originalTag}
                  />
                  {selectedGroupType === 'direct' && (
                    <Button
                      variant="contained"
                      onClick={() => setTarget('tagGroup')}
                      aria-label="add-factory-quote-tag-group"
                      sx={{ flexShrink: 0 }}
                    >
                      {`${originalTag ? '編輯' : '新增'}直接費用`}
                    </Button>
                  )}
                </Stack>
                <StringField
                  label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.name}
                  value={tagName}
                  onChange={setTagName}
                />
                <FilterableDropdown
                  label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.modelType}
                  options={modelTypeOptions}
                  value={selectedModelTypeOption}
                  onChange={(newValue) => setSelectedModelTypeOption(newValue)}
                  disabled={!!originalTag}
                />
                <FilterableDropdown
                  label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.customerCode}
                  options={customerOptions}
                  value={selectedCustomerOption}
                  onChange={(newValue) => setSelectedCustomerOption(newValue)}
                  disabled={!!originalTag}
                />
                <NumberField label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.cost} value={cost} onChange={setCost} />
                <BooleanField
                  label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.disabled}
                  value={disabled}
                  onChange={setDisabled}
                />
              </>
            )}
            {target === 'tagGroup' && (
              <StringField
                label={FACTORY_QUOTE_TAG_FIELD_TO_NAME.groupName}
                value={tagGroupName}
                onChange={setTagGroupName}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (target === 'tag') {
                onRequestClose();
              } else if (target === 'tagGroup') {
                setTarget('tag');
              }
            }}
            variant="outlined"
            disabled={isSubmitting}
          >
            取消
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit || isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : '儲存'}
          </Button>
        </DialogActions>
      </Dialog>
      {notification}
    </>
  );
}

export default FactoryQuoteTagEditDialog;
