import { useDeferredValue, useMemo, useState } from 'react';
import SearchBar from 'src/components/shared/SearchBar';

function useSearchBar(options?: {
  noShrink?: boolean;
}): [JSX.Element, { filterString: string; filterValues: string[] }] {
  const [filterString, setFilterString] = useState('');

  const deferredFilterString = useDeferredValue(filterString);

  const searchBar = useMemo(
    () => <SearchBar value={filterString} onChange={setFilterString} noShrink={options?.noShrink} />,
    [filterString, options?.noShrink],
  );

  return [
    searchBar,
    useMemo(
      () => ({
        filterString: deferredFilterString,
        filterValues: deferredFilterString.split(' ').filter(Boolean),
      }),
      [deferredFilterString],
    ),
  ];
}

export default useSearchBar;
