import { useState } from 'react';
import { Box, Button, Stack, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ImagePreview from '../SampleManagement/ImagePreview';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function ImageField({
  value: imageSrc,
  onChange,
}: {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}) {
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(reader.result?.toString());
        setIsUploading(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => setIsUploading(true);

  return (
    <Stack direction="row" alignItems="end">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
          height: 100,
          border: '2px dashed #ccc',
          borderRadius: '8px',
          overflow: 'hidden',
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={handleClick}
      >
        <Button
          component="label"
          role={undefined}
          variant="text"
          tabIndex={-1}
          sx={{ width: '100%', height: '100%', p: 0 }}
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            '上傳圖片'
          )}
          <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
        </Button>
        {isUploading && (
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </Box>
      {imageSrc && (
        <ImagePreview imageSrc={imageSrc}>
          <SearchIcon />
        </ImagePreview>
      )}
    </Stack>
  );
}

export default ImageField;
