import { TextField } from '@mui/material';

function SearchBar({
  value,
  onChange,
  noShrink,
}: {
  value: string;
  onChange: (value: string) => void;
  noShrink?: boolean;
}) {
  return (
    <TextField
      sx={{ flexShrink: noShrink ? 0 : 1 }}
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      placeholder="Search..."
      size="small"
    />
  );
}

export default SearchBar;
