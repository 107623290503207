import { useCallback, useMemo, useState } from 'react';
import Notification from 'src/components/shared/Notification';

function useNotification() {
  const [notificationParams, setNotificationParams] = useState<
    { message: string; timeout?: number } | undefined
  >();

  const notification = useMemo(
    () =>
      notificationParams ? (
        <Notification
          message={notificationParams.message}
          timeout={notificationParams.timeout}
          onRequestClose={() => setNotificationParams(undefined)}
        />
      ) : null,
    [notificationParams],
  );

  return {
    notification,
    showNotification: useCallback(
      (message: string, timeout?: number) => setNotificationParams({ message, timeout }),
      [],
    ),
  };
}

export default useNotification;
