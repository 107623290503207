import type { BusinessQuoteModel } from 'src/models/businessQuote';

export function calculateBusinessQuoteModel(
  businessQuoteModel: BusinessQuoteModel,
  exchangeRate = 1,
): BusinessQuoteModel {
  const {
    addon,
    factoryQuoteSum,
    orderNumPc = 0,
    profitInPercentage = 0,
    factoryProfit = 0,
  } = businessQuoteModel;

  const cost =
    factoryProfit * exchangeRate +
    factoryQuoteSum +
    addon.reduce((result, { cost: _cost }) => result + _cost, 0);
  const orderNumDz = Math.floor(orderNumPc / 12);
  const fobDz = cost * (1 + profitInPercentage / 100);

  return {
    ...businessQuoteModel,
    cost,
    orderNumDz,
    fobDz,
    fobPc: fobDz / 12.0,
    profit: fobDz - cost,
    costSum: cost * orderNumDz,
    fobSum: fobDz * orderNumDz,
  };
}
