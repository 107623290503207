import { DataGrid } from '@mui/x-data-grid';
import { useCustomerListQuery } from 'src/hooks';
import type { Customer } from 'src/models/customer';
import { CUSTOMER_FIELDS } from './constants';

const columns = CUSTOMER_FIELDS.map(({ field, name }) => ({
  field,
  width: 150,
  headerName: name,
}));

function CustomerTable({
  onSelected,
  filterValues,
}: {
  onSelected?: (customer: Customer | undefined) => void;
  filterValues: string[];
}) {
  const { customerList, isCustomerListLoading } = useCustomerListQuery();

  const rows = customerList
    .filter(({ code }) => code !== '0000')
    .map((customer, index) => ({ ...customer, id: index }));

  return (
    <DataGrid
      loading={isCustomerListLoading}
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={(params) => {
        const rowIndex = params.row.id;
        onSelected?.(customerList[rowIndex]);
      }}
      disableColumnFilter
      disableColumnSelector
      filterModel={{ items: [], quickFilterValues: filterValues }}
      sx={{ minHeight: 300 }}
      rowHeight={30}
    />
  );
}

export default CustomerTable;
