import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBusinessQuoteListQuery, useBusinessQuoteQuery, useCustomerListQuery } from 'src/hooks';
import type { Customer } from 'src/models/customer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addBusinessQuote, updateBusinessQuote } from 'src/apis';
import type { BusinessQuote, BusinessQuoteDetail, BusinessQuoteModel } from 'src/models/businessQuote';
import { useRouter } from 'src/hooks/useRouter';
import useNotification from 'src/hooks/useNotification';
import {
  BUSINESS_QUOTE_CURRENCY_OPTIONS,
  BUSINESS_QUOTE_FIELD_TO_NAME,
  BUSINESS_QUOTE_MODEL_FIELD_TO_NAME,
} from './constants';
import BusinessQuoteDetailTable from './BusinessQuoteDetailTable';
import FactoryQuoteModelSelectDialog from './FactoryQuoteModelSelectDialog';
import Dropdown from '../shared/Dropdown';
import NumberField from '../shared/NumberField';
import FactoryQuoteTagSelectDialog from './FactoryQuoteTagSelectDialog';
import FilterableDropdown from '../shared/FilterableDropdown';

function BusinessQuoteFormBase({
  businessQuote: originalBusinessQuote,
  onRequestClose,
}: {
  businessQuote: BusinessQuoteDetail | undefined;
  onRequestClose: () => void;
}) {
  const queryClient = useQueryClient();
  const { navigate } = useRouter();

  const { customerList } = useCustomerListQuery();
  const { refetchBusinessQuoteList } = useBusinessQuoteListQuery();

  const customerOptions = useMemo(
    () => customerList.map(({ id, code, name }) => ({ value: id, label: `${code} (${name})` })),
    [customerList],
  );

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<{
    label: string;
    value: Customer['id'];
  } | null>(null);

  useEffect(() => {
    if (!originalBusinessQuote) return;
    setSelectedCustomerOption(
      customerOptions.find(({ value }) => value === originalBusinessQuote.customerId) ?? null,
    );
  }, [customerOptions, originalBusinessQuote]);

  const [currency, setCurrency] = useState<BusinessQuoteDetail['currency'] | undefined>(
    originalBusinessQuote?.currency,
  );

  const [exchangeRate, setExchangeRate] = useState<BusinessQuoteDetail['exchangeRate'] | undefined>(
    originalBusinessQuote?.exchangeRate,
  );

  const [isModelSelectDialogOpen, setIsModelSelectDialogOpen] = useState(false);

  const [isAddonSelectDialogOpen, setIsAddonSelectDialogOpen] = useState(false);

  const [selectedBusinessQuoteDetailIndex, setSelectedBusinessQuoteDetailIndex] = useState(-1);

  const [businessQuoteDetail, setBusinessQuoteDetail] = useState(originalBusinessQuote?.detail ?? []);

  const [isSubmitting, setSubmitting] = useState(false);

  const [version, setVersion] = useState(originalBusinessQuote?.version);

  const { notification, showNotification } = useNotification();

  const onModelSelectDialogSubmit = useCallback((newBusinessQuoteModels: BusinessQuoteDetail['detail']) => {
    setIsModelSelectDialogOpen(false);
    setBusinessQuoteDetail((prev) => [...prev, ...newBusinessQuoteModels]);
  }, []);

  const onAddonSelectDialogSubmit = useCallback(
    (newBusinessQuoteModel: BusinessQuoteModel) => {
      setIsAddonSelectDialogOpen(false);
      setBusinessQuoteDetail((prev) => {
        const detailItem = prev[selectedBusinessQuoteDetailIndex];
        if (detailItem) {
          return prev.toSpliced(selectedBusinessQuoteDetailIndex, 1, {
            ...detailItem,
            data: newBusinessQuoteModel,
          });
        }
        return [...prev, { data: newBusinessQuoteModel }];
      });
    },
    [selectedBusinessQuoteDetailIndex],
  );

  const onModelTableRequestEdit = useCallback(
    (modelIndex: number) => {
      setSelectedBusinessQuoteDetailIndex(modelIndex);
      setIsAddonSelectDialogOpen(true);
    },
    [setIsAddonSelectDialogOpen, setSelectedBusinessQuoteDetailIndex],
  );

  const addBusinessQuoteMutation = useMutation({ mutationFn: addBusinessQuote });

  const updateBusinessQuoteMutation = useMutation({ mutationFn: updateBusinessQuote });

  const onSubmit = useMemo(() => {
    if (
      selectedCustomerOption !== null &&
      !businessQuoteDetail.some(
        ({ data: { orderNumPc, profitInPercentage, factoryProfit } }) =>
          orderNumPc === undefined || profitInPercentage === undefined || factoryProfit === undefined,
      )
    ) {
      return async () => {
        try {
          setSubmitting(true);
          const newBusinessQuote = originalBusinessQuote
            ? await updateBusinessQuoteMutation.mutateAsync({
                ...originalBusinessQuote,
                detail: businessQuoteDetail,
                currency: currency ?? 'NTD',
                exchangeRate: exchangeRate ?? 1,
                version: version ?? originalBusinessQuote.version,
              })
            : await addBusinessQuoteMutation.mutateAsync({
                customerId: selectedCustomerOption.value,
                detail: businessQuoteDetail,
                currency: currency ?? 'NTD',
                exchangeRate: exchangeRate ?? 1,
              });

          setVersion(newBusinessQuote.version);
          showNotification('儲存成功', 2000);
          queryClient.setQueryData(['businessQuote', { id: newBusinessQuote.id }], newBusinessQuote);
          refetchBusinessQuoteList();
          setTimeout(() => {
            setSubmitting(false);
            navigate({ id: newBusinessQuote.id });
          }, 2000);
        } catch (error) {
          if (error instanceof Error) {
            showNotification(error.message);
          } else {
            console.error(error);
          }
          setSubmitting(false);
        }
      };
    }

    return undefined;
  }, [
    addBusinessQuoteMutation,
    businessQuoteDetail,
    currency,
    exchangeRate,
    navigate,
    originalBusinessQuote,
    queryClient,
    refetchBusinessQuoteList,
    selectedCustomerOption,
    showNotification,
    updateBusinessQuoteMutation,
    version,
  ]);

  const selectedBusinessQuoteDetail = businessQuoteDetail[selectedBusinessQuoteDetailIndex];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, overflow: 'hidden', flexGrow: 1 }}>
        <h1>業務報價單</h1>
        <Stack direction="row" alignItems="center" gap={1}>
          <FilterableDropdown
            label={BUSINESS_QUOTE_FIELD_TO_NAME.customerCode}
            options={customerOptions}
            value={selectedCustomerOption}
            onChange={(newValue) => {
              setSelectedCustomerOption(newValue);
              setBusinessQuoteDetail(originalBusinessQuote?.detail ?? []);
            }}
            disabled={!!originalBusinessQuote}
          />
          <Dropdown
            label={BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.currency}
            options={BUSINESS_QUOTE_CURRENCY_OPTIONS}
            value={currency}
            onChange={setCurrency}
          />
          <NumberField
            label={BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.exchangeRate}
            value={exchangeRate}
            onChange={setExchangeRate}
          />
          <Button
            disabled={selectedCustomerOption === null}
            sx={{ flexShrink: 0 }}
            variant="contained"
            aria-label="import-factory-quote"
            onClick={() => setIsModelSelectDialogOpen(true)}
          >
            匯入工廠報價
          </Button>
        </Stack>
        <BusinessQuoteDetailTable
          businessQuoteDetail={businessQuoteDetail}
          setBusinessQuoteDetail={setBusinessQuoteDetail}
          onRequestEdit={onModelTableRequestEdit}
          exchangeRate={exchangeRate}
        />
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1} py={1}>
          <Button onClick={onRequestClose} variant="outlined" disabled={isSubmitting}>
            取消
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!onSubmit || isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : '儲存'}
          </Button>
        </Stack>
        {isModelSelectDialogOpen && selectedCustomerOption && (
          <FactoryQuoteModelSelectDialog
            customerId={selectedCustomerOption.value}
            onRequestClose={() => setIsModelSelectDialogOpen(false)}
            onSubmitted={onModelSelectDialogSubmit}
          />
        )}
        {isAddonSelectDialogOpen && selectedCustomerOption && selectedBusinessQuoteDetail && (
          <FactoryQuoteTagSelectDialog
            customerId={selectedCustomerOption.value}
            businessQuoteModel={selectedBusinessQuoteDetail.data}
            onRequestClose={() => setIsAddonSelectDialogOpen(false)}
            onSubmitted={onAddonSelectDialogSubmit}
          />
        )}
      </Box>
      {notification}
    </>
  );
}

function BusinessQuoteForm({
  businessQuoteId,
  onRequestClose,
}: {
  businessQuoteId?: BusinessQuote['id'];
  onRequestClose: () => void;
}) {
  const { businessQuote, isBusinessQuoteLoading, isBusinessQuoteFetched } =
    useBusinessQuoteQuery(businessQuoteId);

  if (businessQuote?.id !== businessQuoteId || !isBusinessQuoteFetched || isBusinessQuoteLoading) {
    return (
      <>
        <h1>業務報價單</h1>
        <CircularProgress />
      </>
    );
  }

  return <BusinessQuoteFormBase businessQuote={businessQuote} onRequestClose={onRequestClose} />;
}

export default BusinessQuoteForm;
