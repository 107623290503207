import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Notification({
  message,
  onRequestClose,
  timeout,
}: {
  message: string;
  onRequestClose: () => void;
  timeout?: number;
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open
      message={message}
      autoHideDuration={timeout}
      onClose={timeout ? onRequestClose : undefined}
      action={
        timeout ? undefined : (
          <IconButton size="small" aria-label="close" color="inherit" onClick={onRequestClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
    />
  );
}

export default Notification;
