import type { FactoryQuoteTagGroup } from 'src/models/factoryQuote';
import type { SampleTagSaveFormat } from 'src/models/sample';

export const SAMPLE_TAG_FIELD_TO_NAME = {
  sampleTagGroupName: '項目名稱',
  sampleTagName: '選項名稱',
  sampleTagType: '選項類型',
} as const;

export const SAMPLE_TAG_FIELD_TYPE_TO_NAME: Record<SampleTagSaveFormat['type'], string> = {
  string: '文字',
  boolean: '是/否',
  number: '數字',
  image: '圖片',
} as const;

export const FACTORY_QUOTE_TAG_FIELD_TO_NAME = {
  groupType: '費用類別',
  groupName: '項目名稱',
  name: '選項名稱',
  modelType: '形體類別',
  customerCode: '客戶代號',
  customerName: '客戶名稱',
  cost: '價格NT/打',
  disabled: '停用',
} as const;

export const FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME: Record<FactoryQuoteTagGroup['type'], string> = {
  direct: '直接費用',
  indirect: '間接費用',
  addon: '業務加價',
} as const;
