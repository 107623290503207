import { Box, Dialog } from '@mui/material';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';

function ImagePreview({
  imageSrc,
  thumbnailSize = 50,
  children,
}: PropsWithChildren<{ imageSrc: string; thumbnailSize?: number }>) {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => setOpen(true);

  const handleMouseLeave = () => setOpen(false);

  return (
    <>
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children ?? (
          <Box
            component="img"
            src={imageSrc}
            alt="Thumbnail"
            sx={{
              width: thumbnailSize,
              height: thumbnailSize,
              objectFit: 'cover',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          />
        )}
      </Box>
      <Dialog open={open} sx={{ pointerEvents: 'none', '&.MuiPaper-root': { zIndex: '1000 !important' } }}>
        <Box
          component="img"
          src={imageSrc}
          alt="Preview"
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Dialog>
    </>
  );
}

export default ImagePreview;
