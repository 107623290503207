import { Box, Button, ButtonGroup, Stack, Typography } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useFactoryQuoteTagListQuery } from 'src/hooks';
import type { FactoryQuoteTag } from 'src/models/factoryQuote';
import type { BusinessQuoteDetail } from 'src/models/businessQuote';
import { BUSINESS_QUOTE_MODEL_FIELD_TO_NAME } from './constants';
import NumberField from '../shared/NumberField';
import StringField from '../shared/StringField';
import { FACTORY_QUOTE_MODEL_FIELD_TO_NAME } from '../FactoryQuote/constants';
import { calculateBusinessQuoteModel } from './utils';
import FactoryQuoteHistoryButton from '../FactoryQuoteHistory/FactoryQuoteHistoryButton';
import DeleteButton from '../shared/DeleteButton';

const CELL_SIZE = { width: 200, height: 30 };

function BusinessQuoteDetailTable({
  businessQuoteDetail,
  setBusinessQuoteDetail,
  onRequestEdit,
  exchangeRate = 1.0,
}: {
  businessQuoteDetail: BusinessQuoteDetail['detail'];
  setBusinessQuoteDetail: Dispatch<SetStateAction<BusinessQuoteDetail['detail']>>;
  onRequestEdit: (index: number) => void;
  exchangeRate: BusinessQuoteDetail['exchangeRate'] | undefined;
}) {
  const { factoryQuoteTagList } = useFactoryQuoteTagListQuery();

  const factoryQuoteTagIdMap = useMemo(
    () =>
      factoryQuoteTagList.reduce<Record<number, FactoryQuoteTag>>(
        (result, factoryQuoteTag) => ({ ...result, [factoryQuoteTag.id]: factoryQuoteTag }),
        {},
      ),
    [factoryQuoteTagList],
  );

  const columns = useMemo(
    () => [
      { field: 'actions', headerName: '' },
      { field: 'customerModel', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.customerModel },
      { field: 'moldQuoteLocation', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.moldQuoteLocation },
      { field: 'modelName', headerName: FACTORY_QUOTE_MODEL_FIELD_TO_NAME.modelName },
      { field: 'factoryQuoteSum', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.factoryQuoteSum },
      ...Array.from(
        new Set(businessQuoteDetail.flatMap(({ data: { addon } }) => addon).map(({ id }) => id)),
      ).map((tagId) => ({
        field: tagId.toString(),
        headerName: factoryQuoteTagIdMap[tagId]?.name ?? '',
      })),
      { field: 'factoryProfit', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.factoryProfit },
      { field: 'cost', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.cost },
      { field: 'profit', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.profit },
      { field: 'orderNumPc', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.orderNumPc },
      { field: 'orderNumDz', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.orderNumDz },
      { field: 'fobDz', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.fobDz },
      { field: 'fobPc', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.fobPc },
      { field: 'costSum', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.costSum },
      { field: 'fobSum', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.fobSum },
      { field: 'profitInPercentage', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.profitInPercentage },
      { field: 'refMarketPrice', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.refMarketPrice },
      { field: 'refHistoryQuote', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.refHistoryQuote },
      {
        field: 'refCustomerTargetPrice',
        headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.refCustomerTargetPrice,
      },
      { field: 'remarks', headerName: BUSINESS_QUOTE_MODEL_FIELD_TO_NAME.remarks },
    ],
    [businessQuoteDetail, factoryQuoteTagIdMap],
  );

  const rows = useMemo(
    () =>
      businessQuoteDetail.map<{ id: string } & Record<string, ReactNode>>(
        (
          {
            data: {
              addon,
              customerModel,
              orderNumPc,
              profitInPercentage,
              factoryQuoteSum,
              profit,
              fobDz,
              fobPc,
              fobSum,
              costSum,
              cost,
              modelName,
              factoryProfit,
              refCustomerTargetPrice,
              refHistoryQuote,
              refMarketPrice,
              remarks,
              ...rest
            },
            itemId,
          },
          detailIndex,
        ) => {
          const costItems = addon.reduce<Record<string, ReactNode>>(
            (result, { id, cost: _cost }) => ({ ...result, [id.toString()]: _cost / exchangeRate }),
            {},
          );

          const editableNumberFields = [
            { key: 'orderNumPc', value: orderNumPc },
            { key: 'profitInPercentage', value: profitInPercentage },
            { key: 'factoryProfit', value: factoryProfit },
            { key: 'refCustomerTargetPrice', value: refCustomerTargetPrice },
            { key: 'refMarketPrice', value: refMarketPrice },
            { key: 'refHistoryQuote', value: refHistoryQuote },
          ].reduce<Record<string, ReactNode>>((result, { key, value }) => {
            return {
              ...result,
              [key]: (
                <NumberField
                  value={value ?? undefined}
                  onChange={(newValue) => {
                    setBusinessQuoteDetail((prev) => {
                      const detailItem = prev[detailIndex]
                        ? { ...prev[detailIndex], data: { ...prev[detailIndex].data, [key]: newValue } }
                        : prev[detailIndex];

                      if (detailItem)
                        detailItem.data = calculateBusinessQuoteModel(detailItem.data, exchangeRate);

                      return detailItem ? prev.with(detailIndex, detailItem) : prev;
                    });
                  }}
                />
              ),
            };
          }, {});

          const editableStringFields = [
            { key: 'customerModel', value: customerModel },
            { key: 'modelName', value: modelName },
            { key: 'remarks', value: remarks },
          ].reduce<Record<string, ReactNode>>(
            (result, { key, value }) => ({
              ...result,
              [key]: (
                <StringField
                  value={value ?? undefined}
                  onChange={(newValue = '') => {
                    setBusinessQuoteDetail((prev) => {
                      const detailItem = prev[detailIndex]
                        ? { ...prev[detailIndex], data: { ...prev[detailIndex].data, [key]: newValue } }
                        : prev[detailIndex];

                      return detailItem ? prev.with(detailIndex, detailItem) : prev;
                    });
                  }}
                />
              ),
            }),
            {},
          );

          return {
            ...rest,
            ...costItems,
            ...editableNumberFields,
            ...editableStringFields,
            profit: (profit / exchangeRate).toFixed(2),
            fobDz: (fobDz / exchangeRate).toFixed(2),
            fobPc: (fobPc / exchangeRate).toFixed(2),
            fobSum: (fobSum / exchangeRate).toFixed(2),
            costSum: (costSum / exchangeRate).toFixed(2),
            cost: (cost / exchangeRate).toFixed(2),
            factoryQuoteSum: (
              <FactoryQuoteHistoryButton
                itemId={itemId}
                label={(factoryQuoteSum / exchangeRate).toFixed(2)}
              />
            ),
            actions: (
              <ButtonGroup variant="text">
                <Button onClick={() => onRequestEdit(detailIndex)}>加價項目</Button>
                <DeleteButton
                  onClick={() => setBusinessQuoteDetail((prev) => prev.toSpliced(detailIndex, 1))}
                />
              </ButtonGroup>
            ),
            id: detailIndex.toString(),
          };
        },
      ),
    [businessQuoteDetail, exchangeRate, onRequestEdit, setBusinessQuoteDetail],
  );

  return (
    <Box sx={{ overflow: 'auto', flexGrow: 1, mt: 1 }}>
      {rows.length > 0 && (
        <Stack direction="row" gap={2}>
          <Stack>
            {columns.map((column) => (
              <Box
                key={column.field}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: CELL_SIZE.width,
                  height: CELL_SIZE.height,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {column.headerName}
                </Typography>
              </Box>
            ))}
          </Stack>
          {rows.map((row) => (
            <Stack key={row.id}>
              {columns.map((column) => (
                <Box
                  key={column.field}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: CELL_SIZE.width,
                    height: CELL_SIZE.height,
                  }}
                >
                  {row[column.field] ?? ''}
                </Box>
              ))}
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
}
export default BusinessQuoteDetailTable;
