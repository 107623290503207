import { DataGrid } from '@mui/x-data-grid';
import type { FactoryQuoteTag, FactoryQuoteTagGroup } from 'src/models/factoryQuote';
import {
  useFactoryQuoteTagGroupListQuery,
  useCustomerListQuery,
  useFactoryQuoteTagListQuery,
} from 'src/hooks';
import { useMemo } from 'react';
import type { Customer } from 'src/models/customer';
import { FACTORY_QUOTE_TAG_FIELD_TO_NAME, FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME } from '../constants';

const columns = Object.keys(FACTORY_QUOTE_TAG_FIELD_TO_NAME)
  .map((field) => field as keyof typeof FACTORY_QUOTE_TAG_FIELD_TO_NAME)
  .map((field) => ({
    field,
    width: 150,
    headerName: FACTORY_QUOTE_TAG_FIELD_TO_NAME[field],
  }));

function FactoryQuoteTagTable({
  onSelected,
  filterValues,
}: {
  onSelected?: (factoryQuoteTag: FactoryQuoteTag | undefined) => void;
  filterValues: string[];
}) {
  const { factoryQuoteTagGroupList, isFactoryQuoteTagGroupListLoading } = useFactoryQuoteTagGroupListQuery();
  const { factoryQuoteTagList, isFactoryQuoteTagListLoading } = useFactoryQuoteTagListQuery();
  const { customerList, isCustomerListLoading } = useCustomerListQuery();

  const factoryQuoteTagGroupIdMap = useMemo(
    () =>
      factoryQuoteTagGroupList.reduce<Record<number, FactoryQuoteTagGroup>>(
        (result, factoryQuoteTagGroup) => ({ ...result, [factoryQuoteTagGroup.id]: factoryQuoteTagGroup }),
        {},
      ),
    [factoryQuoteTagGroupList],
  );

  const customerIdMap = useMemo(
    () =>
      customerList.reduce<Record<number, Customer>>(
        (result, customer) => ({ ...result, [customer.id]: customer }),
        {},
      ),
    [customerList],
  );

  const rows = factoryQuoteTagList.flatMap((factoryQuoteTag) => {
    const factoryQuoteTagGroup = factoryQuoteTagGroupIdMap[factoryQuoteTag.group];
    const customer =
      typeof factoryQuoteTag.customerId === 'number' ? customerIdMap[factoryQuoteTag.customerId] : undefined;

    if (!factoryQuoteTagGroup) return [];

    return {
      id: factoryQuoteTag.id,
      groupType: FACTORY_QUOTE_TAG_GROUP_TYPE_TO_NAME[factoryQuoteTagGroup.type],
      groupName: factoryQuoteTagGroup.name,
      name: factoryQuoteTag.name,
      modelType: factoryQuoteTag.modelType,
      customerCode: customer?.code,
      customerName: customer?.name,
      cost: factoryQuoteTag.cost,
      disabled: factoryQuoteTag.disabled ? '停用' : '',
    };
  });

  return (
    <DataGrid
      loading={isFactoryQuoteTagGroupListLoading || isFactoryQuoteTagListLoading || isCustomerListLoading}
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableRowSelectionOnClick
      onRowClick={(params) => onSelected?.(factoryQuoteTagList.find(({ id }) => id === params.row.id))}
      disableColumnFilter
      disableColumnSelector
      filterModel={{ items: [], quickFilterValues: filterValues }}
      sx={{ minHeight: 300 }}
      rowHeight={30}
    />
  );
}

export default FactoryQuoteTagTable;
