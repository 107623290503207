import {
  getBusinessQuote,
  getBusinessQuoteList,
  getCustomerList,
  getFactoryQuote,
  getFactoryQuoteHistoryList,
  getFactoryQuoteItemList,
  getFactoryQuoteList,
  getFactoryQuoteTagForCustomerList,
  getFactoryQuoteTagGroupList,
  getFactoryQuoteTagList,
  getModelTypeList,
  getSampleList,
  getSampleTagGroupList,
  getSampleTagList,
} from 'src/apis';
import { useCallback } from 'react';
import type { FactoryQuoteTagForCustomer } from 'src/models/factoryQuote';
import { useGetListQuery, useGetQuery } from './queryHookBase';

export const useSampleTagGroupListQuery = () => {
  const queryFn = useCallback(() => getSampleTagGroupList(), []);
  return useGetListQuery({ queryKey: 'sampleTagGroupList', queryFn });
};

export const useSampleTagListQuery = () => {
  const queryFn = useCallback(() => getSampleTagList(), []);
  return useGetListQuery({ queryKey: 'sampleTagList', queryFn });
};

export const useSampleListQuery = () => {
  const queryFn = useCallback(() => getSampleList(), []);
  return useGetListQuery({ queryKey: 'sampleList', queryFn });
};

export const useCustomerListQuery = () => {
  const queryFn = useCallback(() => getCustomerList(), []);
  return useGetListQuery({ queryKey: 'customerList', queryFn });
};

export const useFactoryQuoteTagGroupListQuery = () => {
  const queryFn = useCallback(() => getFactoryQuoteTagGroupList(), []);
  return useGetListQuery({ queryKey: 'factoryQuoteTagGroupList', queryFn });
};

export const useFactoryQuoteTagListQuery = () => {
  const queryFn = useCallback(() => getFactoryQuoteTagList(), []);
  return useGetListQuery({ queryKey: 'factoryQuoteTagList', queryFn });
};

export const useFactoryQuoteTagForCustomerListQuery = ({
  customerId,
  modelType,
  types,
}: {
  customerId: number | undefined;
  modelType: string | undefined;
  types: FactoryQuoteTagForCustomer['type'][];
}) => {
  const disabled = customerId === undefined || modelType === undefined;

  const queryFn = useCallback(() => {
    if (disabled) return getFactoryQuoteTagForCustomerList();

    const searchParams = new URLSearchParams();
    searchParams.set('customerId', customerId.toString());
    searchParams.set('modelType', modelType);
    searchParams.set('type', types.join(','));

    return getFactoryQuoteTagForCustomerList({ search: `?${searchParams.toString()}` });
  }, [customerId, disabled, modelType, types]);

  return useGetListQuery({
    queryKey: 'factoryQuoteTagForCustomerList',
    queryFn,
    disabled,
    extraQueryKeys: [customerId, modelType, types.join(' ')],
  });
};

export const useFactoryQuoteListQuery = () => {
  const queryFn = useCallback(() => getFactoryQuoteList(), []);
  return useGetListQuery({ queryKey: 'factoryQuoteList', queryFn });
};

export const useFactoryQuoteItemListQuery = ({ customerId }: { customerId: number | undefined }) => {
  const disabled = customerId === undefined;

  const queryFn = useCallback(() => {
    if (disabled) return getFactoryQuoteItemList();

    const searchParams = new URLSearchParams();
    searchParams.set('customerId', customerId.toString());

    return getFactoryQuoteItemList({ search: `?${searchParams.toString()}` });
  }, [customerId, disabled]);

  return useGetListQuery({
    queryKey: 'factoryQuoteItemList',
    queryFn,
    disabled,
    extraQueryKeys: [customerId],
  });
};

export const useFactoryQuoteHistoryListQuery = ({ itemId }: { itemId?: string }) => {
  const disabled = itemId === undefined;

  const queryFn = useCallback(() => {
    if (disabled) return getFactoryQuoteHistoryList();

    const searchParams = new URLSearchParams();
    searchParams.set('itemId', itemId.toString());

    return getFactoryQuoteHistoryList({ search: `?${searchParams.toString()}` });
  }, [itemId, disabled]);

  return useGetQuery({ queryKey: 'factoryQuoteHistoryList', queryFn, disabled, extraQueryKeys: [itemId] });
};

export const useFactoryQuoteQuery = (id?: number) => {
  const queryFn = useCallback(() => (id === undefined ? null : getFactoryQuote(id)), [id]);
  return useGetQuery({ queryKey: 'factoryQuote', queryFn, extraQueryKeys: [id] });
};

export const useModelTypeListQuery = () => {
  const queryFn = useCallback(() => getModelTypeList(), []);
  return useGetListQuery({ queryKey: 'modelTypeList', queryFn });
};

export const useBusinessQuoteListQuery = () => {
  const queryFn = useCallback(() => getBusinessQuoteList(), []);
  return useGetListQuery({ queryKey: 'businessQuoteList', queryFn });
};

export const useBusinessQuoteQuery = (id?: number) => {
  const queryFn = useCallback(() => (id === undefined ? null : getBusinessQuote(id)), [id]);
  return useGetQuery({ queryKey: 'businessQuote', queryFn, extraQueryKeys: [id] });
};
